import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionLogin } from '../application/actions/auth';
import { APIError, LoginRequest } from '../application/types/auth';
import Button from '../components/Button';
import ErrorBox from '../components/ErrorBox';
import TextInput from '../components/TextInput';
import MKLogo from '../components/icons/MKLogo';

const Login: React.FC = () => {
  // Set the Navigator
  const navigate = useNavigate();
  // Form State
  const [email, setEmail] = useState('');
  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const [password, setPassword] = useState('');
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const [error, setError] = useState<APIError | null>(null);

  // Login Function
  const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Create the Login Request
    const req: LoginRequest = {
      email,
      password,
    };

    // Send the Request
    ActionLogin(
      req,
      () => {
        navigate('/');
      },
      (err) => {
        setError(err);
      }
    );
  };
  // Render
  return (
    <form
      className='flex flex-col items-center justify-center items-center h-screen font-display p-2'
      onSubmit={onLogin}
    >
      <div className='flex flex-col justify-center max-w-[400px] w-full'>
        <MKLogo size='6rem' className='fill-brand-400 mx-auto' />
        <h1 className='text-2xl text-center font-bold text-brand-400'>
          mahal ko
        </h1>
        {error && <ErrorBox message={error.message} />}
        <TextInput
          id='email'
          type='email'
          label='Email Address:'
          value={email}
          onChange={onEmailChange}
        />
        <TextInput
          id='password'
          type='password'
          label='Password:'
          value={password}
          onChange={onPasswordChange}
        />
        <Button type='submit'>Log In</Button>
      </div>
    </form>
  );
};

export default Login;
