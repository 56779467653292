import { jwtDecode } from 'jwt-decode';
import { appAPI } from '../apiConn';
import { setCookie } from '../cookie';
import { APIError, LoginRequest, LoginResponse, jwt } from '../types/auth';

export const ActionLogin = (
  req: LoginRequest,
  onSuccess: () => void,
  onError: (err: APIError) => void
) => {
  appAPI.post('/webapp/auth/login', req).then(
    (res) => {
      // Handle the response
      const response: LoginResponse = res.data;

      // Decode the Token
      const token = jwtDecode<jwt>(response.token);

      // Create the Expiry Date
      const expiry = new Date();
      expiry.setTime(token.exp * 1000);

      // Set the Cookies
      setCookie('token', response.token, expiry);
      setCookie('user_id', response.user_id, expiry);

      // Call the Success Callback
      onSuccess();
    },
    (err) => {
      // Get the Error Message
      let error: APIError = err.response.data;

      // Call the Error Method
      onError(error);
    }
  );
};

export const ActionVerifyEmail = (
  code: string,
  onSuccess: () => void,
  onError: (err: APIError) => void
) => {
  appAPI.put('/webapp/auth/verify-email?code=' + code).then(
    () => {
      // Call the Success Callback
      onSuccess();
    },
    (err) => {
      // Get the Error Message
      let error: APIError = err.response.data;

      // Call the Error Method
      onError(error);
    }
  );
};
