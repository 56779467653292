import {
  FavoriteBorderOutlined,
  MailOutline,
  PersonOutline,
  SearchOutlined,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ActionGetUserByID } from '../application/actions/user';
import { getCookie } from '../application/cookie';
import { useAppDispatch } from '../application/hooks';
import { User } from '../application/types/user';

const Dashboard: React.FC = () => {
  // Set the Navigator
  const navigate = useNavigate();

  // Get the Dispatcher
  const dispatch = useAppDispatch();

  // Get the User ID
  const userID = getCookie('user_id');

  // User State
  const [user, setUser] = useState<User | null>(null);

  // Effect Hook
  useEffect(() => {
    // Get the Logged In User
    if (userID && !user) {
      dispatch(
        ActionGetUserByID(
          userID,
          (user) => {
            setUser(user);
            if (!user.profile_setup_complete) {
              navigate('/setup-profile');
            } else if (!user.search_preferences_complete) {
              navigate('/setup-search');
            }
          },
          (err) => {
            navigate('/login');
          }
        )
      );
    }
  }, [user, userID, dispatch, navigate]);

  // Render
  return (
    <div className='flex flex-col h-screen font-display'>
      <main className='flex flex-col items-center py-16 flex-1'>
        <div className='max-w-[800px] w-full flex flex-col flex-1'>
          <Outlet />
        </div>
      </main>
      <footer className='fixed bottom-0 left-0 w-full h-16  bg-gradient-to-r from-brand-400 to-purple-950'>
        <nav className='flex items-center justify-center h-full text-white'>
          <ul className='max-w-[400px] w-full flex justify-center space-x-8'>
            <li className='grow'>
              <a
                className='flex flex-col items-center hover:underline'
                href='/'
              >
                <SearchOutlined />
                <span className='text-xs sm:text-base'>Browse</span>
              </a>
            </li>
            <li className='grow'>
              <a
                href='/matches'
                className='flex flex-col items-center hover:underline'
              >
                <FavoriteBorderOutlined />
                <span className='text-xs sm:text-base'>Matches</span>
              </a>
            </li>
            <li className='grow'>
              <a
                href='/messages'
                className='flex flex-col items-center hover:underline'
              >
                <MailOutline />
                <span className='text-xs sm:text-base'>Messages</span>
              </a>
            </li>
            <li className='grow'>
              <a
                href='/profile'
                className='flex flex-col items-center hover:underline'
              >
                <PersonOutline />
                <span className='text-xs sm:text-base'>Profile</span>
              </a>
            </li>
          </ul>
        </nav>
      </footer>
    </div>
  );
};

export default Dashboard;
