import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { User } from "../types/user";

// Define a type for the slice state
interface UserState {
  user?: User;
}

// Define the initial state using that type
const initialState: UserState = {};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

// Export the Slice Actions
export const { setUser } = userSlice.actions;

// Export the Selector
export const selectUser = (state: RootState) => state.user;

// Export the Reducer
export default userSlice.reducer;
