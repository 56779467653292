import axios from 'axios';
import { deleteCookie, getCookie } from './cookie';

export const appAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

// Add an interceptor to include the authentication token in the request headers
appAPI.interceptors.request.use(
  (config) => {
    const token = getCookie('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const isLoggedIn = (): boolean => {
  let jwt = getCookie('token');
  if (jwt === '' || jwt === null || jwt === undefined) {
    return false;
  }
  let user_id = getCookie('user_id');
  if (user_id === '' || user_id === null || user_id === undefined) {
    return false;
  }
  return true;
};

export const clearSession = () => {
  deleteCookie('token');
};
