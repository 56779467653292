import { Dispatch } from '@reduxjs/toolkit';
import { appAPI } from '../apiConn';
import { setUser } from '../features/user';
import { APIError } from '../types/auth';
import { User } from '../types/user';

export const ActionGetUserByID =
  (
    userID: string,
    onSuccess: (user: User) => void,
    onError: (error: APIError) => void
  ) =>
  async (dispatch: Dispatch) => {
    appAPI.get('/users/' + userID).then(
      (res) => {
        // Handle the response
        const user: User = res.data;

        // Set the User in Redux
        dispatch(setUser(user));

        // Call the Success Callback
        onSuccess(user);
      },
      (err) => {
        // Get the Error Message
        let error: APIError = err.response.data;

        // Call the Error Method
        onError(error);
      }
    );
  };
