type ErrorBoxProps = {
  code?: string;
  message?: string;
};

const ErrorBox: React.FC<ErrorBoxProps> = (props: ErrorBoxProps) => {
  // Render
  return (
    <div className="bg-rose-700 text-white p-4 rounded-2xl">
      {props.code && <div className="font-bold">{props.code}</div>}
      {props.message && <div>{props.message}</div>}
    </div>
  );
};

export default ErrorBox;
