type TextInputProps = {
  id: string;
  type: 'text' | 'email' | 'password' | 'date';
  label: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplate?: boolean;
  required?: boolean;
};

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  // Render
  return (
    <div className='flex flex-col pb-4'>
      <label htmlFor={props.id} className='p-2 text-sm font-bold'>
        {props.label}
      </label>
      <input
        type={props.type}
        id={props.id}
        name={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        className='px-2 py-3 rounded-xl border border-gray-400 focus:outline-none focus:border-brand-400'
        autoComplete={props.autoComplate ? 'on' : 'off'}
        required={props.required}
      />
    </div>
  );
};

export default TextInput;
