import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import LoadingSpinner from './components/Loading';
import RequireAuth from './components/RequireAuth';
import Login from './pages/Login';

function App() {
  // Auth Pages
  const VerifyEmail = React.lazy(() => import('./pages/VerifyEmail'));
  // Setup Forms
  const SearchSetup = React.lazy(() => import('./pages/SearchSetup'));
  const SearchEdit = React.lazy(() => import('./pages/SearchEdit'));
  const ProfileSetup = React.lazy(() => import('./pages/ProfileSetup'));

  // General Pages
  const Browse = React.lazy(() => import('./pages/Browse'));
  const Profile = React.lazy(() => import('./pages/Profile'));
  const Matches = React.lazy(() => import('./pages/Matches'));
  const Messages = React.lazy(() => import('./pages/Messages'));
  const Chat = React.lazy(() => import('./pages/Chat'));
  const MyProfile = React.lazy(() => import('./pages/MyProfile'));

  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route
          path='/verify-email/:code'
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <VerifyEmail />
            </Suspense>
          }
        />
        <Route
          path='/'
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Browse />
              </Suspense>
            }
          />
          <Route
            path='/profile'
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <MyProfile />
              </Suspense>
            }
          />
          <Route
            path='/profile/:id'
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path='/matches'
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Matches />
              </Suspense>
            }
          />
          <Route
            path='/messages'
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Messages />
              </Suspense>
            }
          />
          <Route
            path='/chat/:id'
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Chat />
              </Suspense>
            }
          />
        </Route>
        <Route
          path='/setup-profile'
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <RequireAuth>
                <ProfileSetup />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/setup-search'
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <RequireAuth>
                <SearchSetup />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/update-search'
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <RequireAuth>
                <SearchEdit />
              </RequireAuth>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
