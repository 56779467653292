import IconProps from "./IconProps";

const MKLogo: React.FC<IconProps> = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 570.32 665.96"
      width={props.size}
      className={props.className}
    >
      <path d="m569.87,209.97c-1.13-17.12-4.17-33.87-10.84-49.77-15.91-37.9-45.01-59.5-84.64-67.63-26.11-5.35-52.23-3.64-78.22,1.22-31.62,5.91-61.64,16.63-90.57,30.57-1.03.49-2.07.94-3.12,1.41-4.05-5.55-7.88-11-11.91-16.29-22.44-29.49-47.94-55.88-78.66-76.9-19.94-13.64-41.12-24.71-64.96-29.82-33.39-7.15-63.71-.52-90.25,21.27-18.6,15.27-31.71,34.73-41.33,56.59C.62,114.18-2.32,149.36,1.55,185.4c2.58,24.02,8.1,47.4,15.53,70.34,14.03,43.36,33.89,84.1,56.18,123.71,31.58,56.12,68.06,109.03,105.71,161.17,3.59,4.97,7.17,9.95,10.86,15.06-6.46,3.83-12.68,7.52-18.89,11.22-16.29,9.71-32.63,19.35-48.85,29.17-11.98,7.26-17.92,20.4-15.45,33.99,4.31,23.7,29.89,34.88,50.62,22.44,23.19-13.91,46.44-27.7,69.67-41.53.68-.41,1.4-.75,2.15-1.15.39.52.65.84.87,1.18,8.52,12.82,17.25,25.5,25.49,38.5,8.03,12.66,22.09,18.9,36.5,15.55,20.64-4.8,31.91-28.48,21.95-47.03-4.83-8.99-10.62-17.47-16.17-26.05-3.78-5.85-7.9-11.48-11.95-17.33.57-.42,1.01-.79,1.49-1.09,27.43-17.69,54.38-36.08,80.5-55.66,37.74-28.27,73.77-58.5,106.11-92.92,24.44-26.02,46.39-53.94,63.74-85.24,22.36-40.34,35.34-83.08,32.26-129.77Zm-70.25,47.51c-6.32,25.81-18.31,49.02-33.1,70.89-17.58,26.01-38.48,49.19-61.06,70.89-38.99,37.47-82,69.88-126.68,100.15-10.6,7.18-21.35,14.15-32.14,21.29-5.65-7.81-11.18-15.41-16.68-23.04-22.21-30.81-43.94-61.94-64.44-93.91-20.01-31.2-38.94-63.03-55.4-96.27-14.54-29.36-27.15-59.49-35.54-91.25-5.34-20.21-8.75-40.7-8.19-61.69.6-22.93,5.78-44.56,19.04-63.67,3.35-4.83,7.17-9.45,11.39-13.53,11.14-10.79,24.52-12.9,39.17-9.09,15.76,4.09,29.42,12.27,42.35,21.82,15.41,11.39,29.11,24.64,41.82,38.93,15.72,17.68,29.67,36.65,41.83,56.95,10.22,17.07,29.67,22.02,46.93,11.93,28.14-16.43,57.85-28.97,89.58-36.58,13.97-3.35,28.13-5.43,42.48-5.55,8.89-.07,17.75.92,26.39,3.38,17.88,5.09,28.52,17.07,32.96,34.78,5.33,21.22,4.45,42.46-.72,63.57Z" />
    </svg>
  );
};

export default MKLogo;
