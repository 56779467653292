import { PropsWithChildren } from 'react';

type ButtonProps = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
} & PropsWithChildren;

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  // Render
  return (
    <button
      type={props.type || 'submit'}
      onClick={props.onClick}
      disabled={props.disabled}
      className='flex gap-2 justify-center bg-brand-400 text-white font-display font-bold py-4 rounded-2xl hover:bg-brand-600 transition-colors duration-200 ease-in-out w-full'
    >
      {props.prefixIcon}
      <span>{props.children}</span>
      {props.suffixIcon}
    </button>
  );
};

export default Button;
